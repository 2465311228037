import gql from 'graphql-tag';


export default gql`
  mutation AuthenticateUser(
    $email: String!
    $password: String!
  ) {
    authenticateUser(
      input: {
        email: $email
        password: $password
      }
    ) {
      authenticationToken
      refreshToken
      user {
        name
        markedDone
      }
    }
  }
`;

export type AuthenticateUserData = {
  authenticationToken: string;
  refreshToken: string;

  user: {
    name: string;
    markedDone: string[];
  };
};
