
  import {
    defineComponent,
    reactive,
    ref,
  } from 'vue';

  import {
    useRouter,
  } from 'vue-router';

  import {
    useMutation,
  } from '@vue/apollo-composable';


  import {
    useSecurityStore,
  } from '../store';

  import AuthenticateUserMutation, {
    AuthenticateUserData,
  } from '../graphql/mutations/AuthenticateUser';


  export default defineComponent({
    setup() {
      const securityStore = useSecurityStore();
      const router = useRouter();

      const incorrectCreds = ref(false);

      /**
       * ...
       */
      const fields = reactive({
        email: '',
        password: '',
      });

      /**
       * ...
       */
      const {
        onError: onLoginError,
        onDone: onLoginDone,
        mutate: doLogin,
      } = useMutation(AuthenticateUserMutation);

      /**
       * ...
       */
      onLoginDone((result) => {
        const data = result?.data?.authenticateUser as AuthenticateUserData;

        if (!data) {
          incorrectCreds.value = true;

          return;
        }

        securityStore.setTokens(data.authenticationToken, data.refreshToken);
        securityStore.setName(data.user.name);

        window.localStorage.setItem('objects_done', JSON.stringify(data.user.markedDone));

        router.push({
          name: 'home',
        });
      });

      /**
       * ...
       */
      onLoginError((error) => {
        console.error(error);
      });

      /**
       * Called when the user submits the form.
       */
      const onFormSubmit = (): void => {
        incorrectCreds.value = false;

        doLogin({
          password: fields.password,
          email: fields.email,
        });
      };

      return {
        onFormSubmit,

        incorrectCreds,
        fields,
      };
    },
  });
